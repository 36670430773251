import React, {useEffect, useMemo, useState} from "react"
import {Button, CardContent, Divider, Paper, Typography} from "@mui/material"
import {AddPoint} from "./AddPoint";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {generateClient} from "aws-amplify/api";
import {ListPointOfIntrestsQuery, PointOfIntrest} from "../../API";
import {listPointOfIntrests} from "../../graphql/queries";
import {GraphQLResult} from "@aws-amplify/api-graphql";
import {getPointLabel, ParkPoint, PointIcon, PointMark, pointsInSectionList} from "./PointMark";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import "./AddPointSection.css"
import data from "../../data/geoJson.json";
import aucklandData from "../../data/aucklandGeoJson.json";
import {Layer, Source} from "react-map-gl";
import {dataCircleLayer, dataLayer, dataLineLayer} from "../map/mapUtils";
import {DefaultMap} from "../map/DefaultMap";
import {PointInformation} from "./PointInformation";

type AddPointSectionProps = {
  parkId: string
  location?: {lat: number, lon: number, zoom: number}
}
export const AddPointSection = ({parkId, location}: AddPointSectionProps) => {
  const {user} = useAuthenticator((context) => [context.user])
  const client = generateClient()
  const [points, setPoints] = useState<[] | PointOfIntrest[]>([])
  const [usersPoints, setUsersPoints] = useState<[] | PointOfIntrest[]>()

  const [selectedPoint, setSelectedPoint] = useState<PointOfIntrest>()
  const [createPoint, setCreatePoint] = useState<boolean>(false)

  const parkData = useMemo(() => {
    // @ts-ignore
    return parkId ? data.features.find(feature => feature.properties.id === parkId) : undefined
  }, [parkId]);
  const aucklandParkData = useMemo(() => {
    // @ts-ignore
    return parkId ?  aucklandData.features.find(feature => feature.properties.id === parkId) : undefined
  }, [parkId]);

  useEffect(() => {
    const pointsPromise = client.graphql({
      query: listPointOfIntrests, authMode: user ? 'userPool' : 'iam', variables: {
        filter: {
          parkId: {
            eq: parkId
          }
        }
      }

    }) as Promise<GraphQLResult<ListPointOfIntrestsQuery>>

    pointsPromise.then((res) => {
      const items = res.data?.listPointOfIntrests?.items ?? []

      const allPoints = items.filter((item): item is PointOfIntrest => Boolean(item))

      setPoints(allPoints)
      setUsersPoints(allPoints.filter((item) => item?.owner === user.username))
    })
  }, [user, parkId, createPoint])

  return (<>
    <Typography variant="h6" fontWeight={'bolder'} component="div" paddingTop={'3rem'}>Points of Interest</Typography>
    {location && (<div style={{height: "50vh"}}>
        <DefaultMap mapProps={{style: {width: "calc(100vw - 4rem)", height: "50vh"}}} location={location}>
          {parkData && (<Source type="geojson" data={parkData}>
            <Layer {...dataLineLayer}/>
            <Layer {...dataLayer}/>
          </Source>)}
          {aucklandParkData && (
            <Source
              type="geojson"
              // @ts-ignore
              data={aucklandParkData}
            >
            <Layer {...dataCircleLayer} />
          </Source>)}
          {points.flatMap((point) => {
            return <PointMark
              key={point.id}
              longitude={point.longitude}
              latitude={point.latitude}
              pointType={ParkPoint.parse(point.pointType)}
              draggable={false}
              onClick={() => {
                setSelectedPoint(point)
              }}
            />
          })}
        </DefaultMap>
    </div>)}
    {points.filter(point => pointsInSectionList.includes(ParkPoint.parse(point.pointType))).map(point => {
      return <Paper
        key={point.id}
        elevation={2}
      >
        <CardContent>
          <div className="AddPointSection-header">
            <div className="AddPointSection-user">
              <PointIcon pointType={ParkPoint.parse(point.pointType)}/>
              <Typography variant="subtitle1" fontWeight={'bolder'}
                          component="div">{point.name || getPointLabel(ParkPoint.parse(point.pointType))}</Typography>
            </div>
          </div>
          {point.description && <>
              <div className="AddPointSection-info">
                  <Typography variant="body1" component="div" className="AddPointSection-info-capitalize-first-letter">
                    {point.description}
                  </Typography>
              </div>
              <Divider/>
          </>}
          <div className="AddPointSection-buttons">
            <Button
              variant="contained"
              size={'large'}
              endIcon={<AssistantDirectionIcon/>}
              onClick={() => {
                window.open(`https://maps.google.com?q=${point.latitude},${point.longitude}`)
              }}
            >
              Navigate
            </Button>
          </div>
        </CardContent>
      </Paper>
    })}

    <Button
      variant="contained"
      size={'large'} onClick={() => setCreatePoint(true)}>Add{usersPoints && usersPoints.length > 0 && (" / Update")}
    </Button>
    <Divider/>
    {selectedPoint && (<PointInformation
      point={selectedPoint}
      onClose={() => {
        setSelectedPoint(undefined)
      }}
    />)}
    {createPoint && (<AddPoint
      usersPoints={usersPoints || []}
      onClose={() => {
        setCreatePoint(false)
      }}
      parkData={parkData || aucklandParkData}
    />)}
  </>)
}

