import React, {useMemo} from 'react';
import Map, {GeolocateControl, NavigationControl} from 'react-map-gl';
import {getStyleJson} from "data/styleJson"
import {useSearchParams} from "react-router-dom";
import {MapProps} from "react-map-gl/src/exports-mapbox";

type Props = { mapProps?: MapProps, location?: {lat: number, lon: number, zoom: number} } & React.PropsWithChildren

export function DefaultMap({mapProps, location, children}: Props) {
  const [params] = useSearchParams()

  const initialViewState = useMemo(() => {
    if(location) {
      return location
    }
    if(params) {
      const lat = params.get("lat")
      const lon = params.get("lon")
      const zoom = params.get("zoom") || '13'
      if (lat && lon) {
        return {lat: parseFloat(lat), lon: parseFloat(lon), zoom: parseFloat(zoom)}
      }
    }
    return {
      lon: 174.845749, lat: -41.182734, zoom: 10
    }
  }, [params, location])

  return (<Map
    terrain={{source: "basemaps-elevation-terrain-rgb-dem", exaggeration: 1.5}}
    mapboxAccessToken={process.env.REACT_APP_MAP_BOX_KEY}
    initialViewState={{longitude: initialViewState.lon, latitude: initialViewState.lat, zoom: initialViewState.zoom}}
    style={{width: '100vw', height: '100vh'}}
    mapStyle={getStyleJson(process.env.REACT_APP_BASEMAPS_API_KEY || "")}
    onRender={(event) => event.target.resize()}
    interactiveLayerIds={['data']}
    {...mapProps}
  >
    <GeolocateControl
      position='top-right'
      trackUserLocation={true}
      showUserLocation={true}
      showAccuracyCircle={true}
      positionOptions={{enableHighAccuracy: true}}
      showUserHeading={true}
    />
    <NavigationControl showZoom={false} visualizePitch={true}/>
    {children}

  </Map>)
}