import {useParams, useSearchParams} from "react-router-dom";
import data from "data/searchData.json";
import React, {useEffect, useMemo, useState} from "react";
import {Button, CardContent, IconButton, Link, Paper, Rating, Typography} from "@mui/material";
import {AssistantDirection, Bookmark, BookmarkBorder, Send} from '@mui/icons-material'
import "./SearchResult.css"
import {ReviewSection} from "components/review/ReviewSecton";
import {ListParkReviewsQuery, ListUserSavedParksQuery, ParkReview, UserSavedParks} from "API";
import {generateClient, GraphQLResult} from "aws-amplify/api";
import {listParkReviews, listUserSavedParks} from "graphql/queries";
import {AddPointSection} from "../../../components/point/AddPointSection";
import {deleteUserSavedParks} from "../../../graphql/mutations";
import {CreateBookMark} from "../../../components/saved-parks/CreateBookMark";


export function SearchResult() {
  const {parkId} = useParams()
  const client = generateClient()
  const [params] = useSearchParams()
  const option = useMemo(() => data.find(v => v.id === parkId), [parkId])

  const [showCreateBookMark, setShowCreateBookMark] = useState(false)

  const [averageRating, setAverageRating] = useState<number | null>(null)
  const [bookMarkId, setBookMarkId] = useState<string>()
  const [bookMarkSaveLoading, setBookMarkSaveLoading] = useState<boolean>(false)


  const location = useMemo(() => {
    if (params) {
      const lat = params.get("lat")
      const lon = params.get("lon")
      const zoom = params.get("zoom")
      if (lat && lon && zoom) {
        return {lat: parseFloat(lat), lon: parseFloat(lon), zoom: parseFloat(zoom)}
      }
    }
    if (option && option.searchArea) {
      return {lat: option.searchArea.lat, lon: option.searchArea.lon, zoom: 13}
    }
  }, [params, option])


  useEffect(() => {
    const reviewsPromise = client.graphql({
      query: listParkReviews, variables: {
        filter: {
          parkId: {
            eq: parkId
          }
        }
      }
    }) as Promise<GraphQLResult<ListParkReviewsQuery>>

    reviewsPromise.then((res) => {
      const items = (res.data?.listParkReviews?.items ?? []).filter((item): item is ParkReview => Boolean(item))
      let sumRating: number | null = null
      items.forEach(item => sumRating = (sumRating || 0) + item.rating)
      setAverageRating(sumRating && items.length > 0 ? (sumRating / items.length) : null)
    })
  }, [client, parkId])

  useEffect(() => {
    const userSavedPromise = client.graphql({
      query: listUserSavedParks, variables: {
        filter: {
          parkId: {
            eq: parkId
          }
        }
      }
    }) as Promise<GraphQLResult<ListUserSavedParksQuery>>

    userSavedPromise.then((res) => {
      const bookMark = (res.data?.listUserSavedParks?.items ?? []).filter((item): item is UserSavedParks => Boolean(item)).at(0)
      if(bookMark)
        setBookMarkId(bookMark.id)
    })
  }, [client, parkId])

  if (!option || !parkId) {
    return <></>
  }

  const doBookmark = async () => {
    setBookMarkSaveLoading(true)
    if(bookMarkId) {
      await client.graphql({
        query: deleteUserSavedParks,
        variables: {
          input: {
            id: bookMarkId
          },
        },
      })
      window.location.reload(); // ew i need to fix this
    } else {
      setShowCreateBookMark(true)
    }
  }

  if (option.type === "area") {
    return <div className={'SearchResult'}>
      <div>
        <Typography variant="h4" paddingTop={'1rem'} fontWeight={'bolder'} component="div">{option.name}</Typography>
        <Typography variant={"body1"} paddingTop={'1rem'} paddingBottom={'1rem'} component={'div'}>
          {option.description}
        </Typography>
        <div className={'SearchResult-Cards'}>
          <Paper elevation={2}>
            <CardContent>
              <Typography variant="h6" fontWeight={'bolder'} component="div">Regulation:</Typography>
              <Typography variant="body2">{option.restrictionDescription || "Unknown"}</Typography>
            </CardContent>
          </Paper>
          {option.link && <Paper elevation={2}>
              <CardContent>
                  <Typography variant="h6" fontWeight={'bolder'} component="div">More Information</Typography>
                  <Link href={option.link}>
                      Here for more information of this area
                  </Link>
              </CardContent>
          </Paper>}
        </div>
      </div>
    </div>
  }


  return <div className={'SearchResult'}>
    <div>
      <div className={'SearchResult-header'}>

        <Typography variant="h4" fontWeight={'bolder'} component="div">{option.name}</Typography>
        <IconButton
          style={{padding: 0}}
          aria-label="back"
          size={'large'}
          disabled={bookMarkSaveLoading}
          onClick={doBookmark}
        >
          {bookMarkId ? <Bookmark fontSize={"large"} /> : <BookmarkBorder fontSize={"large"} />}
        </IconButton>
      </div>
      {averageRating && <Rating name="average-rating" key={option.id} value={averageRating} readOnly precision={0.25}/>}

      <Typography variant={"body1"} paddingTop={'1rem'} paddingBottom={'1rem'}
                  component={'div'}>{option.description}</Typography>
      <div className={'SearchResult-Cards'}>
        <Paper elevation={2}>
          <CardContent>{Array.isArray(option.restrictionDescription) ? <>
            <Typography variant="h6" fontWeight={'bolder'} component="div">Regulations:</Typography>
            <div
              className={"SearchResult-multiRestrictionDescriptions"}>{option.restrictionDescription.map((desc, index) =>
              <div key={index}>
                <Typography variant="subtitle1" fontWeight={'bolder'} component="div">{desc.name}</Typography>
                <Typography variant="body2">{desc.value}</Typography>
              </div>)}
            </div>
          </> : <>
            <Typography variant="h6" fontWeight={'bolder'} component="div">Regulation:</Typography>
            <Typography variant="body2">{option.restrictionDescription || "Unknown"}</Typography>
          </>}</CardContent>
        </Paper>
        {option.information && <Paper elevation={2}>
            <CardContent>
                <>
                    <Typography variant="h6" fontWeight={'bolder'} component="div">Properties:</Typography>
                    <div className={'SearchResult-information'}>{option.information.map((info, index) => <div
                      key={index}>
                      <Typography variant="subtitle1" fontWeight={'bolder'} component="div">{info.name}</Typography>
                      <Typography variant="body2">{info.value}</Typography>
                    </div>)}
                    </div>
                </>
            </CardContent>
        </Paper>}

      </div>
    </div>
    <div className={'SearchResult-buttonGroup'}>
      <Button
        variant="contained"
        size={'large'}
        endIcon={<Send/>}
        onClick={async () => {
          await navigator.share({url: document.location.href, title: "Dog Zones", text: option.name})
        }}
      >
        Share
      </Button>
      <Button
        variant="contained"
        size={'large'}
        endIcon={<AssistantDirection/>}
        onClick={() => {
          if (location) {
            window.open(`https://maps.google.com?q=${location.lat},${location.lon}`)
          }
        }}
      >
        Navigate
      </Button>
    </div>
    <AddPointSection parkId={option.id} location={location}/>
    <ReviewSection parkId={option.id} name={option.name}/>
    {showCreateBookMark && <CreateBookMark parkId={parkId} onClose={() => {
      setShowCreateBookMark(false)
      setBookMarkSaveLoading(false)
    }} />}
  </div>
}
