import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Typography,
} from "@mui/material";
import {Transition} from "../transition/Transition";
import {PointOfIntrest} from "../../API";
import {getPointLabel, Point, PointIcon} from "./PointMark";
import React from "react";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import "./PointInformation.css"

type PointInformationProps = {
  point: PointOfIntrest
  onClose: () => void
}
export function PointInformation({point, onClose}: PointInformationProps) {


  return (
    <Dialog
      fullWidth={true}
      open={true}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <DialogTitle className={"PointInformation-title"}>
        <PointIcon pointType={Point.parse(point.pointType)} />
        {point.name || getPointLabel(Point.parse(point.pointType))}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" component="div" className="AddPointSection-info-capitalize-first-letter">
          {point.description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          variant="contained"
          size={'large'}
          endIcon={<AssistantDirectionIcon/>}
          onClick={() => {
            window.open(`https://maps.google.com?q=${point.latitude},${point.longitude}`)
          }}
        >
          Navigate
        </Button>
      </DialogActions>
    </Dialog>
  )
}