import {useMap} from "react-map-gl";
import {createDonutChart} from "../donut-chart/createDonutChart";
import mapboxgl from "mapbox-gl";
import {dataCircleLayer} from "../map/mapUtils";
import aucklandData from "../../data/aucklandGeoJson.json";


const reg0 = ['<', ['get', 'restriction'], 1];
const reg1 = ['all', ['>=', ['get', 'restriction'], 1], ['<', ['get', 'restriction'], 2]];
const reg2 = ['all', ['>=', ['get', 'restriction'], 2], ['<', ['get', 'restriction'], 3]];
const reg3 = ['all', ['>=', ['get', 'restriction'], 3], ['<', ['get', 'restriction'], 4]];
const reg5 = ['all', ['>=', ['get', 'restriction'], 5], ['<', ['get', 'restriction'], 6]];
const reg10 = ['all', ['>=', ['get', 'restriction'], 10], ['<', ['get', 'restriction'], 11]];

export function AucklandDonutCluster({onClick}) {
  const {current} = useMap();

  if (!current) return <></>
  const map = current.getMap()
  // objects for caching and keeping track of HTML marker objects (for performance)
  const markers = {};
  let markersOnScreen = {};




  map.on('load', () => {
    // add a clustered GeoJSON source for a sample set of auckland-parks
    try {
      map.addSource('auckland-parks', {
        'type': 'geojson',
        'data': aucklandData,
        'cluster': true,
        'clusterRadius': 100,
        'clusterMaxZoom': 12,
        'clusterProperties': {
          // keep separate counts for each magnitude category in a cluster
          'reg0': ['+', ['case', reg0, 1, 0]],
          'reg1': ['+', ['case', reg1, 1, 0]],
          'reg2': ['+', ['case', reg2, 1, 0]],
          'reg3': ['+', ['case', reg3, 1, 0]],
          'reg5': ['+', ['case', reg5, 1, 0]],
          'reg10': ['+', ['case', reg10, 1, 0]]
        }
      });
    } catch (e) { }
    // circle and symbol layers for rendering individual auckland-parks (unclustered points)
    map.addLayer({...dataCircleLayer, minzoom: 5});

    function updateMarkers() {
      const newMarkers = {};
      const features = map.querySourceFeatures('auckland-parks');

      // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
      // and add it to the map if it's not there already
      for (const feature of features) {
        const coords = feature.geometry.coordinates;
        const props = feature.properties;
        if (!props.cluster) continue;
        const id = props.cluster_id;

        let marker = markers[id];
        if (!marker) {
          const el = createDonutChart(props);
          marker = markers[id] = new mapboxgl.Marker({
            element: el
          }).setLngLat(coords);
        }
        newMarkers[id] = marker;

        if (!markersOnScreen[id]) marker.addTo(map);
      }
      // for every marker we've added previously, remove those that are no longer visible
      for (const id in markersOnScreen) {
        if (!newMarkers[id]) markersOnScreen[id].remove();
      }
      markersOnScreen = newMarkers;
    }

    // after the GeoJSON data is loaded, update markers on the screen on every frame
    map.on('render', () => {
      if (!map.isSourceLoaded('auckland-parks')) return;
      updateMarkers();
    });

    map.on('click', (e) => {
      const clickedFeatures = map.queryRenderedFeatures(e.point)
      if(clickedFeatures && clickedFeatures[0]) {
        const { properties } = clickedFeatures[0]
        if (properties?.id) {
          onClick(properties.id)
        }
      }
    })

  });


  return <></>
}