import {Delete, LocalParking, QuestionMark, Shower, Wc, Grass} from "@mui/icons-material";

import {Marker} from "react-map-gl";
import React from "react";
import {z} from "zod";

const SharedPoint = z.union([z.literal('PARKING'), z.literal('TOILET')])
export const ParkPoint = z.union([...SharedPoint.options, z.literal('RUBBISH'), z.literal('SWAMP')])
export const GenericPoint = z.union([z.literal("DOG_WASH"), ...SharedPoint.options])

export const Point = z.union([...ParkPoint.options, ...GenericPoint.options])
export type PointType = z.infer<typeof Point>

export const pointsInSectionList: PointType[] = ["DOG_WASH", "PARKING", "TOILET"]

export type ParkPointType = z.infer<typeof ParkPoint>
export type GenericPointType = z.infer<typeof GenericPoint>

export function getPointLabel(pointType: PointType) {
  switch (pointType) {
    case "TOILET":
      return "Toilet"
    case "RUBBISH":
      return "Rubbish bin"
    case "PARKING":
      return "Parking"
    case "DOG_WASH":
      return "Dog wash"
    case "SWAMP":
      return "Swamp"
  }
}

export function pointTypeToLabelOption(pointType: PointType) {
  return {label: getPointLabel(pointType), id: pointType}
}

export function getPointLabelOptions(genericPoint: boolean) {
  if (genericPoint) {
    return GenericPoint.options.map(option => ({
      label: getPointLabel(option.value), id: option.value
    }))
  }
  return ParkPoint.options.map(option => ({
    label: getPointLabel(option.value), id: option.value
  }))
}

export const isGenericPointType = (pointType: string): pointType is GenericPointType => GenericPoint.options.some(option => option.value === pointType)

function PointTypeToColor(pointType?: PointType): string {
  switch (pointType) {
    case "DOG_WASH":
    case "TOILET":
      return "#7ba1eb"
    case "PARKING":
      return "#81838a"
    case "RUBBISH":
      return "#efce90"
    case "SWAMP":
      return "#d79368"
    default:
      return "#cc4050"
  }
}

export function getMapPointTypeColors() {
  return Array.from(new Set(Point.options)).map(option => {
    return [option.value, PointTypeToColor(option.value)]
  }) satisfies [PointType, string][]
}

export function PointIcon({pointType, opacity = 1}: { pointType: PointType, opacity?: number }) {
  const style: React.CSSProperties = {
    color: '#FFF', background: PointTypeToColor(pointType), padding: 2, borderRadius: 5, opacity
  }

  switch (pointType) {
    case "PARKING":
      return <LocalParking style={style}/>
    case "TOILET":
      return <Wc style={style}/>
    case "RUBBISH":
      return <Delete style={style}/>
    case "DOG_WASH":
      return <Shower style={style}/>
    case "SWAMP":
      return <Grass style={style}/>
    default:
      return <QuestionMark style={style}/>
  }
}

type PointMarkProps = {
  latitude: number,
  longitude: number,
  pointType: PointType,
  onDrag?: (latitude: number, longitude: number) => void,
  onClick?: () => void,
  draggable?: boolean,
  opacity?: number
}

export function PointMark({latitude, longitude, pointType, onDrag, onClick, opacity, draggable = true}: PointMarkProps) {
  return <Marker
    latitude={latitude}
    longitude={longitude}
    draggable={draggable}
    onDragEnd={(e) => onDrag?.(e.lngLat.lat, e.lngLat.lng)}
    onClick={(e) => {
      e.originalEvent.preventDefault()
      e.originalEvent.stopPropagation()
      onClick?.()
    }}
  >
    <PointIcon pointType={pointType} opacity={opacity}/>
  </Marker>
}