/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createParkReview = /* GraphQL */ `mutation CreateParkReview(
  $input: CreateParkReviewInput!
  $condition: ModelParkReviewConditionInput
) {
  createParkReview(input: $input, condition: $condition) {
    id
    parkId
    title
    description
    rating
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateParkReviewMutationVariables,
  APITypes.CreateParkReviewMutation
>;
export const updateParkReview = /* GraphQL */ `mutation UpdateParkReview(
  $input: UpdateParkReviewInput!
  $condition: ModelParkReviewConditionInput
) {
  updateParkReview(input: $input, condition: $condition) {
    id
    parkId
    title
    description
    rating
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateParkReviewMutationVariables,
  APITypes.UpdateParkReviewMutation
>;
export const deleteParkReview = /* GraphQL */ `mutation DeleteParkReview(
  $input: DeleteParkReviewInput!
  $condition: ModelParkReviewConditionInput
) {
  deleteParkReview(input: $input, condition: $condition) {
    id
    parkId
    title
    description
    rating
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteParkReviewMutationVariables,
  APITypes.DeleteParkReviewMutation
>;
export const createPointOfIntrest = /* GraphQL */ `mutation CreatePointOfIntrest(
  $input: CreatePointOfIntrestInput!
  $condition: ModelPointOfIntrestConditionInput
) {
  createPointOfIntrest(input: $input, condition: $condition) {
    id
    parkId
    name
    description
    latitude
    longitude
    pointType
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePointOfIntrestMutationVariables,
  APITypes.CreatePointOfIntrestMutation
>;
export const updatePointOfIntrest = /* GraphQL */ `mutation UpdatePointOfIntrest(
  $input: UpdatePointOfIntrestInput!
  $condition: ModelPointOfIntrestConditionInput
) {
  updatePointOfIntrest(input: $input, condition: $condition) {
    id
    parkId
    name
    description
    latitude
    longitude
    pointType
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePointOfIntrestMutationVariables,
  APITypes.UpdatePointOfIntrestMutation
>;
export const deletePointOfIntrest = /* GraphQL */ `mutation DeletePointOfIntrest(
  $input: DeletePointOfIntrestInput!
  $condition: ModelPointOfIntrestConditionInput
) {
  deletePointOfIntrest(input: $input, condition: $condition) {
    id
    parkId
    name
    description
    latitude
    longitude
    pointType
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePointOfIntrestMutationVariables,
  APITypes.DeletePointOfIntrestMutation
>;
export const createUserSavedParks = /* GraphQL */ `mutation CreateUserSavedParks(
  $input: CreateUserSavedParksInput!
  $condition: ModelUserSavedParksConditionInput
) {
  createUserSavedParks(input: $input, condition: $condition) {
    id
    parkId
    group
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserSavedParksMutationVariables,
  APITypes.CreateUserSavedParksMutation
>;
export const updateUserSavedParks = /* GraphQL */ `mutation UpdateUserSavedParks(
  $input: UpdateUserSavedParksInput!
  $condition: ModelUserSavedParksConditionInput
) {
  updateUserSavedParks(input: $input, condition: $condition) {
    id
    parkId
    group
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserSavedParksMutationVariables,
  APITypes.UpdateUserSavedParksMutation
>;
export const deleteUserSavedParks = /* GraphQL */ `mutation DeleteUserSavedParks(
  $input: DeleteUserSavedParksInput!
  $condition: ModelUserSavedParksConditionInput
) {
  deleteUserSavedParks(input: $input, condition: $condition) {
    id
    parkId
    group
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserSavedParksMutationVariables,
  APITypes.DeleteUserSavedParksMutation
>;
