import Typography from "@mui/material/Typography";
import {ExpandMore} from "@mui/icons-material";

import {AppNavigator} from "../../components/AppNavigator";
import React from "react";
import "./HomePage.css"
import Button from "@mui/material/Button";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

export function HomePage() {
  return <div className={'HomePage'}>
    <Typography className={'HomePage-header'} component="h1" variant="h5" fontSize={"5.5vw"} display={"flex"}
                justifyContent={"center"} fontWeight={"bold"}>
      🐾 Welcome To Dog Zones 🐾
    </Typography>
    <div className={"HomePageContent"}>
      <Typography component="div" variant="body1" paddingBottom={2}>
        Unleash the joy of outdoor adventures with your fury friend using <b>Dog Zones</b>, the ultimate guide to dog
        friendly parks around <b>New Zealand</b><br/>
        <b>Dog Zones</b> is tailored for dog lovers seeking the perfect spot for their pups to play, socialize, and
        enjoy the freedom and nature.<br/>
        With <b>Dog Zones</b>, every day is a paw-some day at the park! 🌲🐕
      </Typography>


      <Typography component="div" variant="h5" fontWeight={"bold"}>
        Features:
      </Typography>
      <ul>
        <li><Typography component="div" variant="body1">
          <b>Explore:</b> Discover new dog parks with detailed descriptions using the map or search, and get detailed
          amenities for each park and user ratings.
        </Typography></li>
        <li><Typography component="div" variant="body1">
          <b>Share:</b> Easily share any park you find with the in built share function
        </Typography></li>
        <li><Typography component="div" variant="body1">
          <b>Navigate:</b> Get there with our integration with google or apple maps
        </Typography></li>
        <li><Typography component="div" variant="body1">
          <b>Personalise:</b> Save your Favorite parks to your custom groups.
        </Typography></li>
      </ul>


      <Typography component="div" variant="subtitle1" fontWeight={"bold"}>
        Councils / Regions:
      </Typography>

      {/*UPPER NORTH ISLAND*/}
      <Accordion>
      <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="div" variant="body1">
            Upper North Island:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li><Typography component="div" variant="body1">
              <b>Far North District</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Whangārei District</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Kaipara District</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Auckland</b>
              <Typography component="div" variant="caption">Only points not outline of each park</Typography>
            </Typography></li>
          </ul>
        </AccordionDetails>
      </Accordion>
      {/*CENTRAL NORTH ISLAND*/}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="div" variant="body1">
            Central North Island:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li><Typography component="div" variant="body1">
              <b>Waikato District</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Tauranga City</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Taupo District</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Gisborne District</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Ruapehu District</b>
            </Typography></li>
          </ul>
        </AccordionDetails>
      </Accordion>
      {/*LOWER NORTH ISLAND*/}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="div" variant="body1">
            Lower North Island:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li><Typography component="div" variant="body1">
              <b>Wellington City</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Lower Hut City</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Upper Hut City</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Porirua City</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Kapiti Coast District</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Greater Wellington Region</b>
            </Typography></li>
          </ul>
        </AccordionDetails>
      </Accordion>
      {/*SOUTH ISLAND*/}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="div" variant="body1">
            South Island:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li><Typography component="div" variant="body1">
              <b>Marlborough District</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Christchurch City</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Dunedin</b>
            </Typography></li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Typography component="div" variant="h5" fontWeight={"bold"} paddingTop={"4rem"}>
        Coming Soon:
      </Typography>
      <ul>
        <li><Typography component="div" variant="body1">
          <b>Socialize:</b> Connect with the community of dog lovers, share tips, arrange play dates, and make new
          friends.
        </Typography></li>
        <li><Typography component="div" variant="body1">
          <b>Stay Informed:</b> Get community updates on park conditions, or community events
        </Typography></li>
        <li><Typography component="div" variant="body1">
          <b>Personalise +:</b> Set reminders, and track your visits
        </Typography></li>
      </ul>

      <Typography component="div" variant="subtitle1" fontWeight={"bold"} paddingBottom={"1rem"}>
        Councils / Regions:
      </Typography>
      <Typography component="div" variant="body1">
        <b>Dog Zones</b> mission is to document every dog park in New Zealand, creating a comprehensive, accessible
        resource for all dog owners. To achieve this we need your help. Join us in building a community-driven platform
        where your feedback and contributions can make the difference
      </Typography>
      <Typography component="div" variant="subtitle2" fontWeight={"bold"} paddingTop={"1rem"}>
        Areas we need your help for
      </Typography>
      {/*UPPER NORTH ISLAND*/}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="div" variant="body1">
            Upper North Island:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li><Typography component="div" variant="body1">
              <b>Auckland:</b>
              <Typography component="div" variant="caption">All park outlines</Typography>
            </Typography></li>
          </ul>
        </AccordionDetails>
      </Accordion>
      {/*CENTRAL NORTH ISLAND*/}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="div" variant="body1">
            Central North Island:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li><Typography component="div" variant="body1">
              <b>Hamilton</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Rotorua</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Bay Of Plenty</b>
            </Typography></li>
          </ul>
        </AccordionDetails>
      </Accordion>
      {/*LOWER NORTH ISLAND*/}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="div" variant="body1">
            Lower North Island:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li><Typography component="div" variant="body1">
              <b>Wairarapa</b>
            </Typography></li>
          </ul>
        </AccordionDetails>
      </Accordion>
      {/*SOUTH ISLAND*/}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="div" variant="body1">
            South Island:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li><Typography component="div" variant="body1">
              <b>Invercargill</b>
            </Typography></li>
            <li><Typography component="div" variant="body1">
              <b>Tasman District</b>
            </Typography></li>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Typography component="div" variant="subtitle1" fontWeight={"bold"} paddingBottom={1} paddingTop={"2rem"}>
        📣 We Value Your Bark! 📣
      </Typography>
      <Typography component="div" variant="body1">
        At <b>Dog Zones</b>, your feedback is the treat that helps us improve! As a solo developer im dedicated to
        creating the best experience for you and your canine companions,
        I'm all ears for your suggestions, ideas, and paw-sitive feedback.<br/>
        <b>Got a new park to add?</b> Let's make <b>Dog Zones</b> better together! If you know of a park that's not on
        our list, share the details,
        and lets collaborate to expand our dog park universe. Your input is invaluable in helping <b>Dog Zones</b> grow
        and thrive.<br/>
      </Typography>
      <Typography component="div" variant="body1" paddingTop={1}>
        Together, we can make <b>Dog Zones</b> the go-to place for every dog owner's needs. Because when it comes to dog
        parks,
        its not just about the space, its about the community. 🐾💬
      </Typography>
      <Button
        href={"mailto: contact@dog-zones.com"}
        type={"button"}
        fullWidth
        variant="contained"
        sx={{mt: 3, mb: 2}}
      >
        Contact Us
      </Button>
    </div>
    <AppNavigator/>
  </div>
}