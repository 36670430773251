import {generateClient} from "aws-amplify/api";
import {userPool} from "../../userpool";
import {ListPointOfIntrestsQuery, PointOfIntrest} from "../../API";
import React, {useEffect, useMemo, useState} from "react";
import {listPointOfIntrests} from "../../graphql/queries";
import {GraphQLResult} from "@aws-amplify/api-graphql";
import {StyleFunction, SymbolLayer} from "mapbox-gl";
import {Layer, Source, useMap} from "react-map-gl";
import {useMapPointImages} from "../../components/point/useMapPointImages";
import {getMapPointTypeColors, isGenericPointType, PointType} from "../../components/point/PointMark";
import {SpeedDial, SpeedDialAction, SpeedDialIcon} from "@mui/material";
import {Add} from "@mui/icons-material";
import {AddPoint} from "../../components/point/AddPoint";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {PointInformation} from "../../components/point/PointInformation";

export function PointsOfInterest() {
  const {current: map} = useMap();
  useMapPointImages()

  const { user } = useAuthenticator((context) => [context.user])
  const client = generateClient()
  const [points, setPoints] = useState<[] | PointOfIntrest[]>([])
  const [createPoint, setCreatePoint] = useState<boolean>(false)
  const [usersPoints, setUsersPoints] = useState<[] | PointOfIntrest[]>([])
  const [selectedPoint, setSelectedPoint] = useState<PointOfIntrest>()


  const pointIdMap = useMemo(() => {
    const pointIdMap = new Map()
    points.forEach(point => pointIdMap.set(point.id, point))
    return pointIdMap
  }, [points])

  useEffect(() => {
    const pointsPromise = client.graphql({
      query: listPointOfIntrests, authMode: user ? 'userPool' : 'iam',
    }) as Promise<GraphQLResult<ListPointOfIntrestsQuery>>

    pointsPromise.then((res) => {
      const items = res.data?.listPointOfIntrests?.items ?? []

      const allPoints = items.filter((item): item is PointOfIntrest => Boolean(item) && isGenericPointType(item?.pointType || ""))
      setPoints(allPoints)
      setUsersPoints(user ? allPoints.filter((item) => item?.owner === user.username) : [])
    })
  }, [])

  const formatPointFeatures = (parkPoint: PointOfIntrest[]): GeoJSON.FeatureCollection<GeoJSON.Geometry> => {
    return {
      type: "FeatureCollection", features: parkPoint.map((point): GeoJSON.Feature => ({
        type: "Feature", properties: {pointType: point.pointType, pointId: point.id}, geometry: {
          coordinates: [point.longitude, point.latitude], type: "Point"
        }, id: point.id
      }))
    }
  }

  const pointFeatures = useMemo(() => {
    return formatPointFeatures(points)
  }, [points])


  const pointHaloColorStyleFunctionStops = getMapPointTypeColors()
  const pointHaloColorStyleFunction: StyleFunction = {
    property: 'pointType',
    type: "categorical",
    stops: pointHaloColorStyleFunctionStops,
    default: '#000',
  }

  const pointImageStyleFunction: StyleFunction = {
    property: 'pointType',
    type: "categorical",
    stops: [["PARKING", 'parking'], ["TOILET", 'toilet'], ["RUBBISH", 'rubbish'], ["DOG_WASH", 'dog-wash']] satisfies [PointType, string][],
    default: 'circle',
  }

  const PointsLayer: SymbolLayer = {
    id: 'point-symbol', type: 'symbol', minzoom: 10, layout: {
      "icon-image": pointImageStyleFunction, 'icon-size': 0.3,
    }, "paint": {
      "icon-color": '#F3F3F3', "icon-halo-color": pointHaloColorStyleFunction, "icon-halo-width": 5, "icon-halo-blur": 0
    }
  };

  map?.on("click", "point-symbol", (event) => {
    const {
      features
    } = event;
    const clickedFeature = features && features[0];

    if(clickedFeature?.properties?.pointId) {
      const point = pointIdMap.get(clickedFeature.properties.pointId)
      setSelectedPoint(point)
    }
  })

  return <>
    <Source id="park-points-of-interest" type="geojson" data={pointFeatures}>
      <Layer {...PointsLayer}/>
    </Source>
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={{position: 'absolute', bottom: "4.5rem", left: "1rem"}}
      icon={<SpeedDialIcon/>}
    >
      <SpeedDialAction
        icon={<Add/>}
        tooltipTitle={"Add point of interest"}
        onClick={() => setCreatePoint(true)}
      />
    </SpeedDial>
    {createPoint && <AddPoint
        usersPoints={usersPoints}
        onClose={() => {
          setCreatePoint(false)
          window.location.reload(); // ew i need to fix this
        }}
    />}
    {selectedPoint && (
      <PointInformation
        point={selectedPoint}
        onClose={() => {setSelectedPoint(undefined)}}
      />)}
  </>
}