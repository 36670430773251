import React, {useCallback, useEffect} from 'react';
import {Layer, Source} from 'react-map-gl';
import './MapPage.css';
import {AppNavigator} from "components/AppNavigator";
import data from "data/geoJson.json";
import {createSearchParams, useNavigate} from "react-router-dom";
import {userPool} from "../../userpool";
import type mapboxgl from 'mapbox-gl'
import {DefaultMap} from "../../components/map/DefaultMap";
import { dataLayer, dataLineLayer} from "../../components/map/mapUtils";
import {PointsOfInterest} from "./PointsOfInterest";
import {AucklandPoints} from "../../components/auckland/AucklandPoints";


export function MapPage() {
  const navigate = useNavigate()
  const user = userPool.getCurrentUser();

  useEffect(() => {
    if (user === null) {
      navigate("/")
    }
  }, [navigate, user])

  const onClick = useCallback((event: mapboxgl.MapLayerMouseEvent) => {
    const {
      features, lngLat, target
    } = event;
    const clickedFeature = features && features[0];

    if (clickedFeature?.properties?.id) {
      navigate({
        pathname: `/search/${clickedFeature.properties.id}`, search: `${createSearchParams({
          lat: `${lngLat.lat}`, lon: `${lngLat.lng}`, zoom: `${target.getZoom()}`,
        })}`,
      })
    }
  }, [navigate]);







  return (<>
    <div className="MapPage">
      <DefaultMap mapProps={{onClick}}>
        {  /* @ts-ignore */}
        <Source type="geojson" data={data}>
          <Layer {...dataLineLayer}/>
          <Layer {...dataLayer}/>
        </Source>
        <AucklandPoints/>
        <PointsOfInterest/>
      </DefaultMap>
    </div>
    <div className={"MapPageNavBar"}>
      <AppNavigator/>
    </div>
  </>);
}
