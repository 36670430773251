// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
import type {CircleLayer, FillLayer} from "react-map-gl";
import {LineLayer, StyleFunction, SymbolLayer} from "mapbox-gl";

export const parksColorStyleFunction: StyleFunction = {
  property: 'restriction',
  stops: [[0, '#006704'], [1, '#03c788'], [2, '#0067d2'], [3, '#cbbb00'], [5, '#e37200'], [10, '#d50024'], [100, '#000'], [101, '#667359']],
  default: '#000',
}
export const dataLayer: FillLayer = {
  id: 'data', type: 'fill', paint: {
    'fill-color': parksColorStyleFunction, 'fill-opacity': 0.2
  }
};

export const dataLineLayer: LineLayer = {
  id: 'dataLine', type: 'line', paint: {
    'line-color': parksColorStyleFunction, 'line-opacity': 0.8, "line-width": ['interpolate', // Set the exponential rate of change to 0.5
      ['exponential', 0.5], ['zoom'], // When zoom is 10, buildings will be 100% transparent.
      14, 2, // When zoom is 18 or higher, buildings will be 100% opaque.
      18, 10]
  }
};

export const dataCircleLayer: CircleLayer = {
  'id': 'auckland-parks-circle',
  'type': 'circle',
  'source': 'auckland-parks',
  'filter': ['!=', 'cluster', true],
  'paint': {
    'circle-color': parksColorStyleFunction,
    'circle-opacity': 0.9,
    'circle-radius': 12
  }
};
