import {
  Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography
} from "@mui/material"
import React, {useEffect, useMemo, useState} from "react"
import {generateClient, GraphQLResult} from "aws-amplify/api"
import {useFormReducer} from "components/form/useFormReducer"
import {createUserSavedParks} from "graphql/mutations"
import {z} from "zod"
import {Transition} from "../transition/Transition";
import {listUserSavedParks} from "../../graphql/queries";
import {ListUserSavedParksQuery, UserSavedParks} from "../../API";

export const DEFAULT_GROUP = "General"

const FormSchema = z.object({
  group: z.string()
})

type CreateBookMarkProps = {
  parkId: string, onClose: () => void
}


export const CreateBookMark = ({parkId, onClose}: CreateBookMarkProps) => {
  const client = generateClient()
  const [loading, setLoading] = useState(false)
  const [allUserSavedParks, setAllUserSavedParks] = useState<UserSavedParks[]>()

  const allGroups: string[] = useMemo(() => {
    return Array.from(new Set(allUserSavedParks?.map(savedPark => savedPark.group || DEFAULT_GROUP)));
  }, [allUserSavedParks])

  useEffect(() => {
    console.log(allGroups)
  }, [allGroups]);

  useEffect(() => {
    const userSavedPromise = client.graphql({
      query: listUserSavedParks
    }) as Promise<GraphQLResult<ListUserSavedParksQuery>>

    userSavedPromise.then((res) => {
      const savedParks = (res.data?.listUserSavedParks?.items ?? []).filter((item): item is UserSavedParks => Boolean(item))
      setAllUserSavedParks(savedParks)

      console.log(savedParks)
    })
  }, [])

  const handleSubmit = async () => {
    await client.graphql({
      query: createUserSavedParks, variables: {
        input: {
          parkId: parkId,
          group: state.group
        },
      },
    })
    window.location.reload(); // ew i need to fix this
  }

  const [state, dispatch, errors] = useFormReducer({
    group: "General",
  }, FormSchema);

  return (<Dialog
    fullWidth={true}
    open={true}
    onClose={onClose}
    TransitionComponent={Transition}
  >
    <DialogTitle>Save Park</DialogTitle>
    <DialogContent>
      <Box paddingTop={"1rem"}>
        <Autocomplete
          defaultValue={state.group}
          onInputChange={(_, newValue) => newValue && dispatch({
            type: "update", payload: {key: "group", value: newValue}
          })}
          freeSolo={true}
          autoSelect={true}
          renderInput={(params) => <TextField {...params} label="Search"/>}
          renderOption={(props, option) => (<Box
            {...props}
            component="li"
          >
            <Typography variant="body1" component="div" paddingLeft={"0.5rem"}>{option}</Typography>
          </Box>)}
          options={allGroups}
        />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} disabled={loading}>Close</Button>
      <Button disabled={loading} onClick={async () => {
        dispatch({type: "submit"})
        if (Object.keys(errors).length === 0) {
          setLoading(true)
          await handleSubmit()
          onClose()
        }
      }} variant="contained">{"Save"}</Button>
    </DialogActions>
  </Dialog>)

}