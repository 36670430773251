// code for creating an SVG donut chart from feature properties

const colors = [
  '#006704',
  '#03c788',
  '#0067d2',
  '#cbbb00',
  '#e37200',
  '#d50024'];

export function createDonutChart(props: { reg0: any; reg1: any; reg2: any; reg3: any; reg5: any; reg10: any; mag100: any;mag101: any;}) {

  const offsets = [];
  const counts = [
    props.reg0,
    props.reg1,
    props.reg2,
    props.reg3,
    props.reg5,
    props.reg10
  ];
  let total = 0;
  for (const count of counts) {
    offsets.push(total);
    total += count;
  }
  const fontSize =
    total >= 1000 ? 16 : total >= 100 ? 16 : total >= 10 ? 14 : 12;
  const r =
    total >= 1000 ? 36 : total >= 100 ? 26 : total >= 10 ? 20 : 12;
  const r0 = Math.round(r * 0.6);
  const w = r * 2;

  let html = `<div>
            <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

  for (let i = 0; i < counts.length; i++) {
    html += donutSegment(
      offsets[i] / total,
      (offsets[i] + counts[i]) / total,
      r,
      r0,
      colors[i]
    );
  }
  html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
            <text dominant-baseline="central" transform="translate(${r}, ${r})">
                ${total.toLocaleString()}
            </text>
            </svg>
            </div>`;

  const el = document.createElement('div');
  el.innerHTML = html;
  return el.firstChild;
}

function donutSegment(start: number, end: number, r: number, r0: number, color: string) {
  if (end - start === 1) end -= 0.00001;
  const a0 = 2 * Math.PI * (start - 0.25);
  const a1 = 2 * Math.PI * (end - 0.25);
  const x0 = Math.cos(a0),
    y0 = Math.sin(a0);
  const x1 = Math.cos(a1),
    y1 = Math.sin(a1);
  const largeArc = end - start > 0.5 ? 1 : 0;

  // draw an SVG path
  return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${
    r + r * y0
  } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${
    r + r0 * x1
  } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${
    r + r0 * y0
  }" fill="${color}" />`;
}