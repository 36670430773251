import React, {useEffect, useMemo, useState} from "react";
import {AppNavigator} from "../../components/AppNavigator";
import {userPool} from "../../userpool";
import "./AccountPage.css"
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {logout} from "../../services/auth/logout";
import {Accordion, AccordionDetails, AccordionSummary, CardContent, Paper} from "@mui/material";
import {ArrowForward, ExpandMore} from "@mui/icons-material";
import {generateClient, GraphQLResult} from "aws-amplify/api";
import {listUserSavedParks} from "../../graphql/queries";
import {ListUserSavedParksQuery, UserSavedParks} from "../../API";
import data from "../../data/searchData.json";
import {DEFAULT_GROUP} from "../../components/saved-parks/CreateBookMark";

export function AccountPage() {
  const user = userPool.getCurrentUser();
  const navigate = useNavigate();
  const client = generateClient()
  const parks = useMemo(() => {
    const options = new Map()
    data.forEach(park => {
      options.set(park.id, park)
    })
    return options
  }, [])
  const [savedParksIdsByGroup, setSavedParkIdsByGroup] = useState<Map<string, string[]>>(new Map())


  useEffect(() => {
    if (user === null) {
      navigate("/")
    }
  }, [user, navigate])

  useEffect(() => {
    const userSavedPromise = client.graphql({
      query: listUserSavedParks
    }) as Promise<GraphQLResult<ListUserSavedParksQuery>>

    userSavedPromise.then((res) => {
      const parksIdsByGroup = new Map<string, string[]>()
      const savedParks = (res.data?.listUserSavedParks?.items ?? []).filter((item): item is UserSavedParks => Boolean(item))

      savedParks.forEach(savedPark => {
        const group: string = savedPark.group || DEFAULT_GROUP
        const parkIds = parksIdsByGroup.get(group) || []
        parksIdsByGroup.set(group, [savedPark.parkId, ...parkIds])
      })

      setSavedParkIdsByGroup(parksIdsByGroup)
    })
  }, [])

  return <div className={'AccountPage'}>
    <div className={"AccountPageContent"}>
      <Typography component="h1" variant="h4">
        Welcome {user?.getUsername()}
      </Typography>
      <Accordion elevation={2}>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="div" variant="h5">
            Saved Parks
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={'AccountPage-saved-park'}>
          {savedParksIdsByGroup.size === 0 ? (<Typography component="div" variant="body1">
            Looks like theres nothing here
          </Typography>) : Array.from(savedParksIdsByGroup.keys()).map(key => {
            return <Accordion elevation={2} defaultExpanded={true} key={key}>
              <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component="div" variant="h6">{key}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={'AccountPage-saved-park'}>
                {savedParksIdsByGroup.get(key)?.map(id => {
                  const park = parks.get(id)
                  return <Paper elevation={2} onClick={() => navigate(`/search/${id}`)}>
                    <CardContent className={"AccountPage-saved-park-information"}>
                      <div className={"AccountPage-saved-park-information-header"}>
                        <Typography variant="h6" fontWeight={'bolder'} component="div">{park.name}</Typography>
                        <ArrowForward fontSize={"large"}/>
                      </div>
                      <Typography variant="body1" component="div">{park.description}</Typography>
                    </CardContent>
                  </Paper>
                })}
              </AccordionDetails>
            </Accordion>
          })}

        </AccordionDetails>
      </Accordion>
      <Button
        onClick={logout}
        type="submit"
        fullWidth
        variant="contained"
        sx={{mt: 3, mb: 2}}
      >
        Log out
      </Button>
    </div>
    <AppNavigator/>
  </div>
}